@use '../../Assets/css/var';

.statsPreviewMain {
    padding-top: 0px;
    padding-bottom: 0px;

    .tablemainnet {
        .tableInr {
            .table-responsive {
                overflow-x: auto;
                // max-height: 295px;
            }

            table {
                margin-bottom: 0px;
                min-width: 510px;
                border-collapse: separate;
                border-spacing: 0px 15px;

                tbody>tr:nth-of-type(odd)>* {
                    background-color: transparent;
                }

                tr {
                    color: #ffffff;
                    border-radius: 6px;
                    margin-bottom: 15px;
                    background-color: rgba(255, 255, 255, 0);
                    font-weight: 700;
                    border: 1px solid #626262;
                    box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);

                    th {
                        border: none;
                        color: #3ddb43;
                        font-weight: 400;
                        vertical-align: middle;
                        padding: 15px;
                        font-size: 16px;
                        text-align: left;
                    }

                    td {
                        border: none;
                        color: #ffffff;
                        font-weight: 400;
                        vertical-align: middle;
                        padding: 15px;

                        img {
                            height: 20px;
                            width: auto;
                            display: inline-block;
                            margin-right: 10px;

                            &.copyIocn {
                                filter: invert(1);
                                cursor: pointer;
                                margin-left: 10px;
                            }
                        }

                        svg {
                            height: 24px;
                            width: auto;
                            margin-right: 10px;
                        }

                        a {
                            background-color: #4caf50;
                            display: inline-block;
                            text-align: center;
                            font-size: 18px;
                            text-decoration: none;
                            font-weight: 500;
                            color: #000000;
                            border-radius: 2px;
                            padding: 3px 8px;
                            line-height: 1;

                            &.links {
                                background-color: transparent;
                                padding: 0;
                            }
                        }

                        &.linkshr.text-end {
                            padding-right: 20px;
                        }

                        &.txtinf {
                            p {
                                margin: 0px;
                                display: inline-block;
                                color: var.$colour-theme;
                            }

                            span {
                                display: inline-block;
                                margin: 0 10px;
                                color: #9d9d9d;
                            }
                        }
                    }
                }
            }
        }
    }
}