@use '../../Assets/css/var';

.treeAeromatix {
    .treebox {
        padding: 20px;
        border-radius: 5px;
        text-align: left;
        background-color: transparent;
        border: none;
        box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
        color: #ffffff;
        height: 100%;
        cursor: pointer;

        .headeline {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            img {
                width: auto;
                height: 20px;
                margin-right: 5px;
                position: relative;
                top: -2px;
            }
        }

        .downSticky {
            position: sticky;
            top: 100%;
        }

        .cartBox {
            text-align: center;
            margin: 30px auto 30px;

            button {
                border: 1px solid #ffffff;
                outline: none;
                background-color: #333333;
                color: #ffffff;
                cursor: pointer;
                width: 50px;
                height: 50px;
                border-radius: 60px;
                display: flex;
                margin-bottom: 10px;
                box-shadow: inset 0px 0px 10px rgb(0, 0, 0);
                align-items: center;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .footerBox {
            justify-content: space-between;
            margin: 0px;
        }

        .footerlineone {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0px;

            svg {
                width: auto;
                height: 20px;
                margin-right: 5px;
            }
        }

        .footerline {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0px;

            svg {
                width: auto;
                height: 20px;
                margin-right: 5px;
            }
        }

        .itemsmain {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;

            .col6 {
                flex: 0 0 50%;
                max-width: 50%;
                display: flex;
                flex-wrap: wrap;
            }

            .col12 {
                flex: 0 0 100%;
                max-width: 100%;
                display: flex;
                flex-wrap: wrap;
            }

            span {
                display: block;
                border-radius: 0px;
                width: 30px;
                height: 30px;
                margin: 0 auto 0px;
                // background-color: var.$colour-theme;
                cursor: pointer;
                // transform: rotate(45deg);

                &.dark {
                    filter: hue-rotate(123deg);
                }
            }

            .col6 {
                .col6 {
                    span {
                        width: 20px;
                        height: 20px;
                        margin-bottom: 10px;
                    }

                    .col6 {
                        span {
                            width: 10px;
                            height: 10px;
                            margin-bottom: 10px;
                        }

                        .col6 {
                            span {
                                width: 6px;
                                height: 6px;
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }

        .imgaeboxNft {
            text-align: center;

            h2 {
                font-size: 14px;
                margin: 0 0 20px;
                text-align: center;
            }

            img {
                height: auto;
                width: 100%;
                border-radius: 3px;
                object-fit: contain;
                position: relative;
            }

            .lazy-load-image-background {
                background-image: url('../../Assets/images/loading.gif');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 50px 50px;
            }
            .imgBxnft{
                &>.lazy-load-image-background{
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }

        span svg {
            width: 22px;
            height: auto;
        }

        .sbdb {
            display: block;
            font-size: 14px;
            opacity: 0.7;
            margin-top: 5px;
            line-height: 1;
        }
    }
}