@use '../../Assets/css/var';

.sf_banner {
    .slide {
        img {
            height: 50vw;
            width: 100%;
            object-fit: cover;
            max-height: 350px;
            background-color: rgba(104, 86, 133, 0.17);
        }
    }

    .sliderbanner {
        position: relative;

        svg:not(.paging-dot) {
            position: absolute;
            top: -60px;
            left: -60px;
            width: 150px;
            height: 150px;
            z-index: 1;
            transform: scale(0.45);
            transform-origin: left top;
            filter: brightness(1.5);
        }
    }

    .paging-item {
        button {
            padding: 6px;
            opacity: 1 !important;

            svg {
                width: 10px;
                height: 10px;
                fill: #ffffff !important;
            }
        }

        &.active {
            button {
                svg {
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }

    .slider-container {
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // background-image: linear-gradient(transparent, #000000);
            z-index: 1;
            pointer-events: none;
        }

        .paging-dot {
            fill: #3ddb43;
        }

        .slider-control-centerleft {
            display: none;
        }

        .slider-control-centerright {
            display: none;
        }
    }

    .contentinfolt {
        max-width: 560px;

        h1 {
            font-size: 68px;
            margin-bottom: 30px;
        }

        p {
            font-size: 24px;
            color: #afafaf;
            margin-bottom: 30px;
        }
    }
}