@use '../../Assets/css/var';


.informationPreviewMain {
    padding-top: 0px;
    padding-bottom: 0px;

    .tablemainnet {
        .col-md-6 {
            margin-bottom: 30px;
        }

        .mainnetInr {
            padding: 30px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
            height: 100%;
            border: 1px solid #1c1c1c;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                width: 30px;
                height: 30px;
                border-top: 4px solid var.$colour-theme;
                border-left: 4px solid var.$colour-theme;
                z-index: 0;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0px;
                right: 0px;
                width: 30px;
                height: 30px;
                border-bottom: 4px solid var.$colour-theme;
                border-right: 4px solid var.$colour-theme;
                z-index: 0;
            }

            h3 {
                font-weight: 400;
                font-size: 24px;
                margin-bottom: 15px;
            }

            p {
                display: block;
                color: #b7b7b7;
                margin: 0 0 5px;
            }
        }

    }
}