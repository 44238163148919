@use '../../Assets/css/var';

.sidebarMenu {
    flex: 0 0 100%;
    max-width: 100% !important;
    margin-right: 0px;
    padding-top: 0px;
    position: relative;
    top: 0px;
    align-self: flex-start;
    margin-top: -20px !important;

    ul {
        padding: 6px;
        margin: 0px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: rgba(9, 56, 11, .62);
        border-radius: 6px;
        border: 1px solid #4c534e;
        box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, .2);

        li {
            margin-bottom: 0px;
            margin: 0 20px;

            a {
                color: #ffffff;
                text-decoration: none;
                padding: 10px 0px;
                display: flex;
                align-items: center;

                span {
                    position: relative;
                    top: 2px;
                }

                svg {
                    margin-right: 5px;
                    transform: scale(0.8);
                    transform-origin: center;
                }

                &.active {
                    color: #4caf50;
                    font-weight: bold;

                    &+a {
                        color: #4caf50;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}